import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { User } from '../user/user.types';

export interface IUserLogin {
    // email: string;
    username: string;
    password: string;
    rememberMe?: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    private readonly baseUrl = environment.baseUrl;

    // private readonly JWT_TOKEN = 'JWT_TOKEN';
    // private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
    // private loggedUser!: string | null;

    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            // 'X-Requested-With': 'XMLHttpRequest',
            // 'Access-Control-Allow-Origin': '*'
        }),
    };

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    get authenticated(): boolean {
        return this._authenticated;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    // signIn(credentials: { email: string; password: string }): Observable<any>
    // {
    //     // Throw error, if the user is already logged in
    //     if ( this._authenticated )
    //     {
    //         return throwError('User is already logged in.');
    //     }

    //     return this._httpClient.post('api/auth/sign-in', credentials).pipe(
    //         switchMap((response: any) =>
    //         {
    //             // Store the access token in the local storage
    //             this.accessToken = response.accessToken;

    //             // Set the authenticated flag to true
    //             this._authenticated = true;

    //             // Store the user on the user service
    //             this._userService.user = response.user;

    //             // Return a new observable with the response
    //             return of(response);
    //         }),
    //     );
    // }

    signIn(user: IUserLogin): Observable<boolean> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }
        return this._httpClient
            .post<any>(`${this.baseUrl}/api/authenticate`, user)
            .pipe(
                switchMap((res: any) => {
                    console.log(res);

                    // hide loading
                    if (res?.id_token) {
                        // Store the access token in the local storage
                        this.accessToken = res?.id_token;

                        // // Set the authenticated flag to true
                        // this._authenticated = true;
                        this.fetchUserData();
                    } else {
                        // show error toast
                    }

                    // Return a new observable with the response
                    return of(true);
                }),
                catchError((error) => {
                    console.log(error);
                    // alert(error.error);
                    // return of(false);
                    return throwError(() => error);
                })
            );
    }

    ///Fetch User Data
    private fetchUserData(): Observable<any> {
        return this._userService.getUserBase().pipe(
            switchMap((_user: any) => {
                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                const user: User = _user;
                user.active = true;
                this._userService.user = user;
                this._userService.activeUser = user;

                // this.sendFCMToken(user.userId);

                // Return true
                return of(true);
            }),
            catchError(() => {
                // Return false
                return of(false);
            })
        );
    }

    ///Send FCM Token
    private sendFCMToken(userId: number) {
        let fcm = '';
        // show loading
        this._userService.sendFCMToken(userId, fcm).subscribe((success) => {
            // hide loading
            if (success) {
                // navigate to home
            }
        });
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient
            .post('api/auth/sign-in-with-token', {
                accessToken: this.accessToken,
            })
            .pipe(
                catchError(() =>
                    // Return false
                    of(false)
                ),
                switchMap((response: any) => {
                    // Replace the access token with the new one if it's available on
                    // the response object.
                    //
                    // This is an added optional step for better security. Once you sign
                    // in using the token, you should generate a new one on the server
                    // side and attach it to the response object. Then the following
                    // piece of code can replace the token with the refreshed one.
                    if (response.accessToken) {
                        this.accessToken = response.accessToken;
                    }

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response.user;

                    // Return true
                    return of(true);
                })
            );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user): Observable<any> {
        console.log(user);
        return this._httpClient.post<any>(
            `${this.baseUrl}/api/user/registration/register`,
            user
        );
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        // return this.signInUsingToken();
        return this.fetchUserData();
    }
}
