import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'app/core/user/user.types';
import {
    AccountsResponse,
    Pagination,
} from 'app/modules/shared/models/Account';

import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccountSelectorService {
    private readonly baseUrl = environment.baseUrl;

    // Private
    private _accounts: BehaviorSubject<User[] | null> = new BehaviorSubject(
        null
    );

    private _pagination: BehaviorSubject<Pagination> = new BehaviorSubject(
        new Pagination()
    );

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for accounts
     */
    get accounts$(): Observable<User[]> {
        return this._accounts.asObservable();
    }

    /**
     * Getter for accounts
     */
    get pagination$(): Observable<Pagination> {
        return this._pagination.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get accounts
     */
    getAccounts(page = 0, size = 20): Observable<User[]> {
        return this._httpClient
            .get<AccountsResponse>(
                `${this.baseUrl}/api/user/care/my-clients/v2`,
                {
                    params: {
                        page,
                        size,
                    },
                }
            )
            .pipe(
                map((data) => {
                    this._accounts.next(data.data);
                    this._pagination.next(data.metadata.pagination);
                    return data.data;
                }),
                catchError((error) => {
                    console.log(error);
                    if (error.status === 403) {
                        this._accounts.next(null);
                        this._pagination.next(new Pagination());
                        return of([]);
                    }
                })
            );
    }
}
