<mat-form-field class="w-full p-4">
    <!-- <mat-label>Main account</mat-label> -->

    <mat-select
        name="account"
        [(ngModel)]="selectedAccount"
        (openedChange)="openChange($event)"
    >
        <input
            class="w-full p-2"
            [formControl]="accountInputCtrl"
            placeholder="Search"
        />
        <cdk-virtual-scroll-viewport
            itemSize="50"
            minBufferPx="200"
            maxBufferPx="400"
            [style.height.px]="4 * 48"
        >
            @if (baseAccount?.userId !== selectedAccount?.userId) {
            <mat-option
                class="base-user"
                [value]="baseAccount"
                (onSelectionChange)="onSelectionChange($event)"
            >
                {{ baseAccount?.firstName }}
                {{ baseAccount?.lastName }}
            </mat-option>
            }
            <mat-option class="active-user hidden" [value]="selectedAccount">
                {{ selectedAccount?.firstName }}
                {{ selectedAccount?.lastName }}
            </mat-option>
            <mat-option
                *cdkVirtualFor="let account of filterAccounts"
                [value]="account"
                (onSelectionChange)="onSelectionChange($event)"
            >
                {{ account.firstName }}
                {{ account.lastName }}
            </mat-option>
            @if (!(filterAccounts | async)?.length) {
            <div class="mt-8 p-4 text-center">
                <ng-container *transloco="let t">
                    @if (this.accountInputCtrl?.value?.length) {
                    {{ t("NO-ACCOUNT") }}
                    } @else {
                    {{ t("NO-PERSONS-CARED-FOR-BY-ME") }}
                    }
                </ng-container>
            </div>
            }
        </cdk-virtual-scroll-viewport>
    </mat-select>
</mat-form-field>
