import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ICountry } from '../models/country';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class CommonService {
    // Private

    private _countries: BehaviorSubject<ICountry[] | null> =
        new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _translocoService: TranslocoService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for countries
     */
    get countries$(): Observable<ICountry[]> {
        return this._countries.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the avatar of the given person
     *
     * @param id
     * @param avatar
     */
    uploadAvatar(id: string, avatar: File): Observable<any> {
        return this._httpClient.post<any>(
            'api/apps/persons/avatar',
            {
                id,
                avatar,
            },
            {
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Content-Type': avatar.type,
                },
            }
        );
    }

    /**
     * Get countries
     */
    getCountries(lang: string = 'de'): Observable<ICountry[]> {
        return this._httpClient
            .get<ICountry[]>(`api/apps/contacts/countries`, {
                params: {
                    lang,
                },
            })
            .pipe(
                tap((countries) => {
                    this._countries.next(countries);
                })
            );
    }

    calculateAge(date) {
        const dob = new Date(date);
        //calculate month difference from current date in time
        const month_diff = Date.now() - dob.getTime();

        //convert the calculated difference in date format
        const age_dt = new Date(month_diff);

        //extract year from date
        const year = age_dt.getUTCFullYear();

        //now calculate the age of the user
        const age = Math.abs(year - 1970);
        console.log(age);
        return age;
    }

    weekDay(day) {
        return this._translocoService.getActiveLang() === 'de'
            ? this.t(day).substr(0, 2)
            : this.t(day).substr(0, 3);
    }

    t(key: string) {
        return this._translocoService.translate(key);
    }
}
