import { User } from 'app/core/user/user.types';

export class Pagination {
    currentPage: 0;
    totalPages: 0;
    pageSize: 0;
    totalCount: 0;
}

export class AccountsResponse {
    metadata: {
        pagination: Pagination;
    };
    data: User[];
}
